import React from 'react';
import './Portfolio.scss';

import PortfolioMainImg from '../../components/PortfolioMainImg/PortfolioMainImg'
import PortfolioGallery from '../../components/PortfolioGallery/PortfolioGallery'
import PortfolioNav from '../../components/PortfolioNav/PortfolioNav'

const baseURL = 'https://content.pxlb.de/'
const token = "58c3ea4c5c06b578fadef3edb66276"
const imgApi = baseURL + 'api/cockpit/image?token=' + token + '&src='

export default class Portfolio extends React.Component{

    constructor(props){
        super(props);

        this.state = {
          error: null,
          isLoaded: false,
          mainImg: false,
          useImg: null,
          key: window.location.href
        }

          //this.getContent();

    }

    setMainImg = (clickImg) => {

        this.setState({mainImg: clickImg})

    }

    getContent = () =>{
        fetch(baseURL + "api/collections/get/Portfolio?token=" + token, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              filter: {url:this.props.url, published: true},
          })
        })
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                entries: result.entries,
                key: window.location.href,
                mainImg: false
              });
          

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });

            }
          )
    }


    componentDidMount() {
        this.getContent();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.url !== this.props.url) {
        this.getContent();
      }
    }



    render(){
      const { error, isLoaded, entries } = this.state;

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {

        return(
          <div className="portfolioPage">
            <div className="container main">
                <PortfolioMainImg data={entries} token={token} baseurl={baseURL} imgapi={imgApi} useImg={this.state.mainImg} key={this.state.mainImg + this.state.key} />
            </div>
            <div className="container gallery">
                <PortfolioGallery data={entries} token={token} baseurl={baseURL} imgapi={imgApi} imgCallback = {this.setMainImg} key={'gallery' + this.state.key} />
                <PortfolioNav data={entries} token={token} baseurl={baseURL} imgapi={imgApi} key={'links' + this.state.key}  />
            </div>
          </div>
        )
      }
    }

}
