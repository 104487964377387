import React from 'react'
import { Link } from "@reach/router"

const baseURL = 'https://content.pxlb.de/';
const token = "58c3ea4c5c06b578fadef3edb66276";

export default class MainNavi extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            error: null,
            isLoaded: false
          };
    }
    
    componentDidMount(){
        fetch(baseURL + '/api/collections/get/'+this.props.get+'?token=' + token, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {published:true, topNavi:true},
                fields: {title: 1, title_slug: 1, url: 1},
                sort: {order:1},
            })
        })
        .then(res=>res.json())
        .then(
            (result) => {
    
              this.setState({
                isLoaded: true,
                pages: result.entries
              });
    
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
    }

    toggleNavi = () =>{
      this.props.toggle();
    }

    Navi = () =>{
        const { error, isLoaded, pages } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } else {
        return(
            pages.map(page => (
                <li key={page.title_slug}>
                    <Link to={this.props.sub + '/' + (page.url ? page.url : page.title_slug)} onClick={this.toggleNavi}>{page.title}</Link>
                </li>
              ))
        );
        }
    }

    render() {
          return (
            <this.Navi />
          );}
    
}
